<template>
  <vx-card>
    <form-wizard
      color="rgba(var(--vs-primary), 1)"
      :title="this.pageTitle"
      :subtitle="this.pageSubTitle"
      :hide-buttons="true"
    >
      <vs-divider class="vx-col w-full" position="left" color="light">Datos Generales</vs-divider>
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label for="institution" class="w-full select-large">Institución</label>
          <v-select
            id="institution"
            name="institution"
            label="name"
            index="id"
            :options="institutions"
            v-model="entryModel.institution"
            v-validate="'required'"
            :searchable="true"
            class="w-full"
          />
          <span class="text-danger">{{ errors.first("institution") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label for="category" class="w-full select-large">Categoría</label>
          <v-select
            id="category"
            name="category"
            label="name"
            index="code"
            :options="categories"
            v-model="entryModel.category"
            v-validate="'required'"
            @input="getSubCategories(entryModel.category)"
            :searchable="true"
            class="w-full"
          />
          <span class="text-danger">{{ errors.first("category") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label for="sub_category" class="w-full select-large">Sub-Categoría</label>
          <v-select
            id="sub_category"
            name="sub_category"
            label="name"
            index="code"
            :options="subcategories"
            v-model="entryModel.sub_category"
            v-validate="'required'"
            :searchable="true"
            class="w-full"
          />
          <span class="text-danger">{{ errors.first("sub_category") }}</span>
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <label for="name" class="w-full select-large">Nombre</label>
          <vs-input
            id="name" name="name" v-model="entryModel.name" v-validate="'required'" class="w-full"
            autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("name") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <label for="title" class="w-full select-large">Título</label>
          <vs-input
            id="title" name="title" v-model="entryModel.title" v-validate="'required'" class="w-full"
            autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("title") }}</span>
          <br/>
        </div>
      </div>

      <vs-divider class="vx-col w-full mt-5" position="right" color="light">Cargar documento</vs-divider>
      <div class="vx-row">
        <div class="vx-col w-full mb-5">
          <uploader
            :classFile="'file-upload-1'"
            :maxNumberOfFiles="1"
            :maxFileSize="26214400"
            @files-removed="removedFiles('file')"
            @files-updated="fileUpload"
            :downloadedFiles="entryModel.media.file"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <label for="description" class="w-full select-large">Descripción de documento</label>
          <editor
            id="description" name="description"
            :content.sync="entryModel.description.html" :resourceType="resource"
          />
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <label for="target" class="w-full select-large">Mostrar en</label>
          <vs-select
            id="target" name="target"
            v-model="entryModel.target" v-validate="'required'"
            class="w-full select-large"
          >
            <vs-select-item :key="'_blank'" :value="'_blank'" :text="'Nueva ventana'" class="w-full"/>
            <vs-select-item :key="'_self'" :value="'_self'" :text="'Ventana actual'" class="w-full"/>
          </vs-select>
          <span class="text-danger">{{ errors.first("target") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <label for="approved" class="w-full select-large">Aprobado</label>
          <vs-select
            id="approved" name="approved" v-model="entryModel.approved" v-validate="'required'"
            class="w-full select-large"
          >
            <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
            <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
          </vs-select>
          <span class="text-danger">{{ errors.first("approved") }}</span>
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <label for="featured" class="w-full select-large">Destacado</label>
          <vs-select
            id="featured" name="featured"
            v-model="entryModel.featured" v-validate="'required'"
            class="w-full select-large"
          >
            <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
            <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
          </vs-select>
          <span class="text-danger">{{ errors.first("featured") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <label for="priority" class="w-full select-large">Prioridad</label>
          <vs-input
            type="number" id="priority" name="priority" min="1" max="10"
            v-model="entryModel.priority" v-validate="'required'"
            class="w-full"
          />
          <span class="text-danger">{{ errors.first("priority") }}</span>
          <br/>
        </div>
      </div>

      <vs-divider class="vx-col w-full" position="left" color="light">Publicación</vs-divider>
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <label for="publish_at" class="w-full select-large">Fecha publicación</label>
          <flat-pickr
            :config="configdateTimePicker"
            id="publish_at" name="publish_at" placeholder="Fecha y hora de publicación"
            v-model="entryModel.publish_at"
            class="w-full flatpickr flatpickr-input"
          />
          <br/>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <label for="expire_at" class="w-full select-large">Fecha expiración</label>
          <flat-pickr
            :config="configdateTimePicker"
            id="expire_at" name="expire_at" placeholder="Fecha y hora de expiración"
            v-model="entryModel.expire_at"
            class="w-full flatpickr flatpickr-input"
          />
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <div class="flex flex-wrap justify-between">
            <vs-row vs-type="flex" vs-justify="flex-end">
              <vs-button color="danger" type="border" class="mb-2" @click="goToEntriesList">Cancelar</vs-button>
              <vs-button class="ml-3 mb-2" @click="createOrUpdateEntry" :disabled="!requiredGeneralDataFieldsFilled">
                Guardar
              </vs-button>
            </vs-row>
          </div>
        </div>
      </div>
    </form-wizard>
  </vx-card>
</template>

<script>
  import AttributeOptionsClient from "../../utils/attributeOptionsClient";
  import CoreClient from "../../utils/coreClient";
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";
  import Configurations from "./../../utils/configurations";
  import Upload from "./../../utils/upload";
  import Editor from "./../../components/Editor.vue";
  import Builder from "./../../components/Builder.vue";
  import Uploader from "./../../components/Uploader.vue";
  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import vSelect from "vue-select";

  let notifier = new Notifier();
  let configurations = new Configurations();
  const ENTRY_CLIENT = new EntriesClient("documents");
  const ATTRIBUTE_OPTIONS_CLIENT = new AttributeOptionsClient("documents");
  const INSTITUTIONS_CLIENT = new CoreClient("institutions");
  const UPLOAD = new Upload("resource");

  export default {
    data() {
      return {
        pageTitle: "Documento Interno",
        pageSubTitle: "Formulario para cargar y editar documentos",
        configdateTimePicker: configurations.configDatePicker(),
        resource: "documents",
        institutions: [],
        categories: [],
        subcategories: [],
        entryModel: {
          institution: null,
          category: null,
          sub_category: null,
          name: null,
          title: null,
          priority: 10,
          featured: false,
          approved: true,
          description: {
            html: null
          },
          type: "hosted",
          target: "_self",
          media: {
            file: {
              url: null,
              meta: {
                title: null,
                description: null
              }
            }
          },
          publish_at: null,
          expire_at: null
        }
      };
    },

    mounted() {
      if (this.$route.params.id !== undefined) {
        this.$vs.loading();
        this.loadEntryData(this.$route.params.id)
          .then(() => {
            this.$vs.loading.close();
          });
      } else {
        this.$vs.loading();
        this.getCategories(false)
          .then(() => {
            this.getInstitutions(false)
              .then(() => {
                this.$vs.loading.close();
              });
          });
      }
    },

    computed: {
      requiredGeneralDataFieldsFilled() {
        return !this.errors.any()
          && this.entryModel.institution !== null
          && this.entryModel.name !== null && this.entryModel.name.trim() !== ""
          && this.entryModel.title !== null && this.entryModel.title.trim() !== ""
          && this.entryModel.description.html !== null && this.entryModel.description.html.trim() !== ""
          && this.entryModel.priority !== null;
      }
    },

    methods: {
      async getInstitutions(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await INSTITUTIONS_CLIENT.all()
          .then(response => {
            this.institutions = response.data;
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getCategories(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await ATTRIBUTE_OPTIONS_CLIENT.categories({queryStrings: {representation_type: "simple"}})
          .then(response => {
            if (showLoading) this.$vs.loading.close();
            this.categories = response.data;
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getSubCategories(category, showLoading = true) {
        if (category !== null && category !== undefined && category !== "") {
          if (showLoading) this.$vs.loading();

          await ATTRIBUTE_OPTIONS_CLIENT.subCategories({
            pathParameters: {categoryId: category.id},
            queryStrings: {representation_type: "simple"}
          })
            .then(response => {
              this.subcategories = response.data;
              if (showLoading) this.$vs.loading.close();
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });

        } else {
          this.subcategories = [];
          this.entryModel.sub_category = null;
        }
      },

      async loadEntryData(entryId) {
        await ENTRY_CLIENT.retrieve({pathParameters: {entryId: entryId}})
          .then(response => {
            this.getInstitutions(false)
              .then(() => {
                this.getCategories(false)
                  .then(() => {
                    this.getSubCategories(response.data.category, false)
                      .then(() => {
                        Object.assign(this.entryModel, response.data);
                      });
                  });
              });
          })
          .catch(error => {
            notifier.notification("error");
          });
      },

      async createOrUpdateEntry() {
        this.$vs.loading();
        this.cleanModel();

        if (this.entryModel.id !== null && this.entryModel.id !== undefined && this.entryModel.id !== "") {
          await ENTRY_CLIENT.update({pathParameters: {entryId: this.entryModel.id}, data: this.entryModel})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("updated");
              this.goToEntriesList();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        } else {
          await ENTRY_CLIENT.create({data: this.entryModel})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("created");
              this.goToEntriesList();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }
      },

      async fileUpload(files) {
        if (files.length > 0 && files[0].source === "Dashboard") {
          this.$vs.loading();
          await UPLOAD.uploadFiles({
            queryStrings: {resource_type: this.resource, content_type: files[0].type},
            files: files
          })
            .then(response => {
              this.entryModel.media.file.url = response;
              this.entryModel.media.file.meta.title = files[0].meta.name;
              this.entryModel.media.file.meta.description = files[0].meta.caption;
              this.$vs.loading.close();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.notification("error");
            });
        }
      },

      async removedFiles(files) {
        let mediaType = this.entryModel.media;
        let body = mediaType[files].url;

        body = JSON.stringify({url: body});

        await UPLOAD.removeFiles({data: body}).then(response => {
          mediaType[files] = {
            url: null,
            meta: {
              title: null,
              description: null
            }
          }
        }).catch(error => {
          notifier.notification("error");
        });
      },

      cleanModel() {
        Object.keys(this.entryModel).forEach(key => {
          if (this.entryModel[key] === null || this.entryModel[key] === "") {
            delete this.entryModel[key];
          }
        })
      },

      goToEntriesList() {
        this.$router.push({name: "documents"});
      },
    },
    components: {
      Editor,
      FormWizard,
      TabContent,
      flatPickr,
      Builder,
      Uploader,
      "v-select": vSelect
    }
  };
</script>